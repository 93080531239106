import React from "react"
import PropTypes from "prop-types"
// Utilities

import Layout from "../components/layout"
import { RiArrowRightSLine } from "react-icons/ri"
import star from "./star.svg"
// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
const TestimonialPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  
<Layout className="page">
   <Helmet title={title} />
    <div className="about-container">
      <h1>Testimonials</h1>
      <br></br>
      <h2>Willy Maxwell recommends Digital AV Solutions Ltd</h2>
      <p>Top service!  Harry has looked after our TV / Sky / Freeview needs for many years and as the various platforms have changed.  Installs of aerials, TVs, screen installations on the wall, tuning issues etc ... Harry has been our Digital AV guru!  Cheers Harry! 5/5 Stars</p>
      <div class="rating">
        <span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span>
      </div>
      <p><i>5/5 Stars</i></p>
      <br></br>
    
    <h2>Janette Norton recommends Digital AV Solutions Ltd.</h2>
    <p>So pleased we had Harry recommended to us for our TV cabling and installations
Very professional, excellent communication, a great result that we are very satisfied with.
Harry delivers the whole package with a competent friendly manner.
Will certainly be giving his name to friends and colleagues.
J & R Norton</p>
    <div class="rating">
        <span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span>
      </div>
      <p><i>5/5 Stars</i></p>
      <br></br>
      <h2>Natalie Townsend recommends Digital AV Solutions Ltd</h2>
      <p>Great service! On time, very friendly and efficient. Highly recommended. Thanks Harry</p>
      <div class="rating">
        <span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span><span><img src={star} alt="Star Alt Text" /></span>
      </div>
      <p><i>5/5 Stars</i></p>
      <br></br>
      </div>
      <Link className="button" to="/contact">Free Quote<span class="icon -right"><RiArrowRightSLine/></span></Link>
      
  </Layout>
)
TestimonialPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}
export default TestimonialPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`